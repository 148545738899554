import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import {
    Table, Input, Divider, Row, Col, Button,
    Typography, Avatar, Dropdown, Space, Tabs, AutoComplete
} from 'antd';
import {
    UserOutlined, DownOutlined,
    FilterOutlined, CloseOutlined
} from '@ant-design/icons'

import { getOrgs, getUsers } from '../api/organization';
import { func, string } from 'prop-types';

export const UsersViewRoute = '/users';

const { Search } = Input;
const { Title } = Typography;

const UsersView = () => {
    const [data, setData] = useState([])
    const [table, setTable] = useState([])
    const [loading, setLoading] = useState(false)
    const [query, setQuery] = useState("");
    const [filter, setFilter] = useState(null)
    const navigate = useNavigate();

    function generateList(data, page) {
        const offset = page ? (page - 1) * 5 : 0
        const list = data.data.map((user, index) => ({
            key: user._id,
            number: (offset + index + 1).toString(),
            name: user.name,
            pic: user.pic || "",
            email: user.email,
            phoneNo: user.phoneNo,
            orgname: user.orgname,
            role: user.role,
        }))
        setData(data)
        setTable(list)
        setLoading(false)
    };



    const fetchData = async (page, f) => {
        setLoading(true)
        const response = await getUsers(page, 5, query, f);
        if (response.Code === 1) {
            generateList(response.Data, page)
        } else {
            setLoading(false)
            navigate('/')
        }
    }


    const onSearch = async (value) => {
        fetchData(1, filter);
    }

    const onSearchChanged = (value) => {
        setQuery(value.target.value);
    }

    const handleFilterChange = (value) => {
        setFilter(value)
        fetchData(1, value)
    }


    useEffect(() => {
        fetchData()
    }, [])

    const columns = [
        {
            title: 'No',
            dataIndex: 'number',
            key: 'sn'
        },
        {
            dataIndex: 'avatar',
            key: 'avatar',
            render: (_, { pic }) => (
                pic || pic !== "" ? <Avatar src={pic} /> : <Avatar icon={<UserOutlined />} />
            )
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Phone',
            dataIndex: 'phoneNo',
            key: 'phoneNo'
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email'
        },
        {
            title: 'Organization',
            dataIndex: 'orgname',
            key: 'orgname'
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role'
        }


    ]

    return (
        <>
            <Helmet><title>Roomie | Users</title></Helmet>
            <Title level={3}>Users</Title>
            <Divider />
            <Row justify="space-between" style={{ marginBottom: "20px" }}>
                <Col>
                    <Search placeholder="Search" onSearch={onSearch} enterButton style={{ marginBottom: "20px" }} onChange={onSearchChanged} />
                </Col>
                <Col>
                    <FilterButton filter={filter} setFilter={handleFilterChange} />
                </Col>
            </Row>
            <Table columns={columns} dataSource={table}
                pagination={{
                    pageSize: 5,
                    total: data.totalCount,
                    loading: { loading },
                    showQuickJumper: true,
                    showTotal: (total) => `Total ${total} users`,
                    onChange: async (page) => {
                        await fetchData(page, filter)
                    }
                }}
            />
        </>
    )
}



export default UsersView;





export const FilterButton = (props) => {

    const [organizations, setOrganizations] = useState([])

    function filterOptions(data) {
        const list = data.data.map((org, index) => ({
            key: org._id,
            label: (
                <a onClick={() => {
                    props.setFilter(org.name)
                }}>{org.name}</a>
            )
        }))
        setOrganizations(list);
    }

    const searchOrganizations = async (value) => {
        const result = await getOrgs(1, 5, value);
        if (result || result.data.length !== 0) {
            filterOptions(result.Data)
        }
    }

    const fetchOrganizations = async () => {
        const orgs = await getOrgs();
        if (orgs.Code === 1) {
            filterOptions(orgs.Data);
        }
    }


    useEffect(() => {
        fetchOrganizations()
    }, [])

    if (props.filter) {
        return <Button danger
            onClick={() => {
                props.setFilter(null)
            }}
        > {
                <Space>
                    <FilterOutlined />
                    {props.filter}
                    <Divider type='vertical' />
                    <CloseOutlined />
                </Space>
            }
        </Button>
    }

    return <Dropdown.Button
        icon={<DownOutlined />}
        placement='bottomLeft'
        trigger={['click']}
        menu={{
            items: organizations
        }}
        dropdownRender={(menu) => (
            <div style={{ backgroundColor: '#F5F5F5', padding: 10, borderRadius: '20px' }}>
                <Tabs defaultActiveKey="1" items={[{ key: '1', label: 'Organizations' }]} />
                <Space direction='vertical'>
                    <AutoComplete onSearch={searchOrganizations}>
                        <Search />
                    </AutoComplete>
                    {React.cloneElement(menu)}
                </Space>

            </div>
        )}
    >
        <Space>
            {<FilterOutlined />}
            Filter
        </Space>
    </Dropdown.Button>
}

FilterButton.propTypes = {
    filter: null || string,
    setFilter: func
}
