import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Layout, theme, Row,
    Button, Space,
    Avatar,
} from 'antd';
import {
    MenuFoldOutlined, MenuUnfoldOutlined,
    UserOutlined
} from '@ant-design/icons';
import Paragraph from 'antd/es/typography/Paragraph';

import Navbar from './Navbar';

const { Header, Content } = Layout

const MainLayout = (props) => {
    const [collapsed, setCollapsed] = useState(false);

    const {
        token: { colorBgContainer }
    } = theme.useToken()




    return <Layout style={{ height: '100vh' }}>
        <Navbar collapsed={collapsed} backgroundColor={colorBgContainer} />
        <Layout>
            <Header
                style={{
                    padding: 0,
                    background: colorBgContainer
                }}
            >
                <Row justify='space-between'>
                    <Button
                        type="text"
                        icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                        onClick={() => setCollapsed(!collapsed)}
                        style={{
                            fontSize: '16px',
                            width: 64,
                            height: 64
                        }}
                    />
                    <div style={{ marginRight: '40px' }}>
                        <Space align='baseline'>
                            <Space>
                                <Avatar icon={<UserOutlined />} />
                            </Space>
                            <Paragraph >{props.username ? props.username : ""}</Paragraph>
                        </Space>
                    </div>
                </Row>
            </Header>
            <Content
                style={{
                    margin: '24px 16px',
                    padding: 24,
                    minHeight: 280,
                    background: colorBgContainer
                }}
            >
                {props.children}
            </Content>
        </Layout>

    </Layout>

}

MainLayout.propTypes = {
    children: PropTypes.node,
    route: PropTypes.any,
    username: PropTypes.string
}


export default MainLayout;

