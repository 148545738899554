import React from "react";
import {
    Form, Input, Layout, Typography,
    Button, Row, Col, Divider,
    message
} from "antd";

import { register } from "../api/organization";
import signupImage from "../assets/signup.png";

const { Title } = Typography;

const layout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 14,
    },
};

export const RegisterationRoute = '/register'

const RegisterationView = () => {

    const [messageApi, contextHolder] = message.useMessage();
    const [form] = Form.useForm();

    const onFinish = async (values) => {
        const registeration = await register(values)
        if (registeration.Code === 2) {
            messageApi.error(registeration.Data.message)
        } else {
            messageApi.success("Successful");
            form.resetFields();
        }
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed: ', errorInfo);

    }

    return <>
        {contextHolder}
        <Layout style={{ height: "100vh" }}>
            <Row justify={"start"} align={"middle"}>
                <Col align='middle' style={{ width: "50%", height: "100vh", backgroundColor: 'white' }}>
                    <img src={signupImage} alt="login" style={{ width: "100%", padding: '80px', marginTop: "25%", }} />
                </Col>
                <Col align="middle" style={{ height: '100vh', width: '50%', padding: '100px' }}>
                    <Title>Admin Registeration</Title>
                    <Divider />
                    <Form
                        form={form}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}

                        {...layout}
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 16,
                        }}
                        style={{
                            maxWidth: 600,
                        }}
                        initialValues={{
                            remember: true,
                        }}
                    >
                        <Form.Item
                            label="Name"
                            name="name"
                            rules={[{
                                required: true,
                                message: "Name can't be empty."
                            }]} >
                            <Input />
                        </Form.Item>


                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[{
                                required: true,
                                message: "Email is required."
                            }]} >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Username"
                            name="username"
                            rules={[{
                                required: true,
                                message: "Username is required"
                            }]} >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Role"
                            name="role"
                            rules={[{
                                required: true,
                                message: "Role is required"
                            }]} >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[{
                                required: true,
                                message: "Password is required"
                            }]} >
                            <Input.Password />
                        </Form.Item>
                        <Form.Item
                            wrapperCol={{
                                offset: 8,
                                span: 16,
                            }}
                        >
                            <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                                Submit
                            </Button>
                            Already registered? <a href="/login">login here</a>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </Layout>
    </>;
}

export default RegisterationView;