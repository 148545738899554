import { React } from "react";
import PropTypes from 'prop-types';
import {
    Modal, Form, Input,
    Button, message
} from "antd";
import { createAdmin, updateAdmin } from "../api/organization";


const layout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 18,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 4,
        span: 16,
    },
};


const FormModal = (props) => {

    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();


    if (props.admin) {
        form.setFieldsValue({
            name: props.admin.name,
            email: props.admin.email,
            username: props.admin.username,
            role: props.admin.role,
        })
    }

    const onReset = () => {
        form.resetFields();
    }
    const onFinish = async (values) => {
        if (props.admin) {

            const res = await updateAdmin(props.admin.key, values);
            if (res.Code !== 1) {
                messageApi.error(res.Data.message)
            } else {
                messageApi.success("Admin Updated")
                await props.refresh();
                props.setShow(false);
            }

        } else {
            const res = await createAdmin(values);
            if (res.Code !== 1) {
                messageApi.error(res.Data.message)
            } else {
                messageApi.success("Admin Created")
                await props.refresh();
                props.setShow(false);
            }

        }
    }

    function onCancel() {
        if (props.admin) {
            props.setShow({ show: false, admin: "empty" })
        } else {
            props.setShow(false);
        }
    }

    return (
        <>
            {contextHolder}
            <Modal
                title={props.admin ? "Update Admin" : "Create Admin"}
                centered
                open={props.show}
                onOk={() => { form.submit() }}
                onCancel={() => onCancel()}
                width={1000}
                okButtonProps={{
                    htmlType: "submit"
                }}
                style={{
                    maxWidth: 600,
                }}
            >
                <Form form={form}
                    onFinish={onFinish}
                    {...layout}
                >
                    <Form.Item label="Name" name="name" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="Email" name="email" rules={[{ required: true }]}>
                        <Input type="email" />
                    </Form.Item>
                    <Form.Item label="Username" name="username" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="Role" name="role" >
                        <Input />
                    </Form.Item>
                    <Form.Item label="Password" name="password" >
                        <Input type="password" />
                    </Form.Item>
                    <Form.Item {...tailLayout}>
                        <Button type="link" onClick={onReset}>Reset</Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

FormModal.propTypes = {
    admin: PropTypes.object,
    refresh: PropTypes.func,
    setShow: PropTypes.func,
    show: PropTypes.bool,

}

export default FormModal;

