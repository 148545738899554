import axios from 'axios';

const url = process.env.REACT_APP_BASE_URL;

const instance = axios.create({
    baseURL: url,
    withCredentials: true,
})

// Organization

export async function getOrgs(page, pageSize, query) {
    try {
        const res = await instance.get(url, {
            params: {
                page: page || 1,
                perPage: 5,
                withCredentials: true,
                query
            },
        }
        );
        return res.data;
    } catch (error) {
        return error.response.data
    }
}

export async function createOrg(data) {
    const org = {
        name: data.name,
        address: data.address,
        phone: data.phone,
        isVerified: data.isVerified || false,
        isActive: data.isActive || false,
    }

    await instance.post(url + 'create', org).then(() => { console.log("Posted") })

}

export async function updateOrg(id, data) {
    const org = {
        _id: id,
        name: data.name,
        address: data.address,
        phone: data.phone,
        isVerified: data.isVerified || false,
        isActive: data.isActive || false,
    }
    await instance.post(url + 'update', org).then(() => console.log("Updated"));
}

export async function deleteOrg(key) {
    const org = {
        _id: key.key,
    };

    await instance.post(url + 'delete', org).then(() => console.log("Deleted"));

}


// Authentication

export async function login(creds) {
    try {
        const match = await instance.post(url, creds);
        return match.data.Data.isAuthenticated;
    } catch (error) {
        if (error.response) {
            return error.response.data.Data.message;
        }
    }
}

export async function register(admin) {
    try {
        const res = await instance.post(url + 'register', admin)
        return res.data;
    } catch (error) {
        return error.response.data;
    }
}

export async function updatePassword(_id, password, newPassword) {
    const packet = {
        _id,
        password,
        newPassword
    }
    try {
        const res = await instance.post(url + 'admin/password', packet)
        return res.data
    } catch (error) {
        return error.response.data
    }


}

export async function logout() {
    await instance.get(url + 'logout')
}

// Admin

export async function getAdmins(page, pageSize, query) {
    try {
        const res = await instance.get(url + 'admins', {
            params: {
                page: page || 1,
                perPage: 5,
                withCredentials: true,
                query
            }
        });
        return res.data;
    } catch (error) {
        return error.response.data
    }
}


export async function getAdminDetails() {
    try {
        const res = await instance.get(url + 'admin');
        return res.data;
    } catch (error) {
        return error.response.data
    }

}

export async function createAdmin(admin) {
    try {
        const res = await instance.post(url + 'admin/create', admin)
        return res.data;
    } catch (error) {
        return error.response.data;
    }
}

export async function updateAdmin(id, data) {
    const admin = {
        _id: id,
        name: data.name,
        email: data.email,
        username: data.username,
        role: data.role,
    }
    await instance.post(url + 'admin/update', admin).then(() => console.log("updated"));
}

// Users

export async function getUsers(page, pageSize, query, orgname) {
    try {
        const res = await instance.get(url + 'users', {
            params: {
                page: page || 1,
                perPage: 5,
                withCredentials: true,
                query,
                orgname
            }
        });
        return res.data;
    } catch (error) {
        return error.response.data
    }
}



