import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom'
import {
  EyeOutlined, DeleteOutlined, SmileOutlined,
  CheckCircleOutlined, ExclamationCircleFilled,

} from '@ant-design/icons'

import {
  Button, Space, Table, Input, Modal,
  Tooltip, Row, Divider, Col, Typography
} from 'antd'

import { deleteOrg, getOrgs } from '../api/organization'
import FormModal from '../components/OrganizationFormModal'

const { Search } = Input;
const { confirm } = Modal;
const { Title } = Typography;
const env = process.env.REACT_APP_ENV

export const OrganizationsRoute = "/";

const OrganizationsView = () => {
  const columns = [
    {
      title: 'No',
      dataIndex: 'number',
      key: 'sn'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address'
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_, { status }) => (
        <Space direction='horizontal'>
          <Tooltip title="Verified">
            {status[0] ? <CheckCircleOutlined /> : <></>}
          </Tooltip>
          <Tooltip title="Active">
            {status[1] ? <SmileOutlined /> : <></>}
          </Tooltip>
        </Space>
      )
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space direction="horizontal">
          <Tooltip title="Delete">
            <Button type="text" danger={true} icon={<DeleteOutlined />} onClick={() => { showDeleteConfirm(record) }} />
          </Tooltip>
          <Tooltip title="View / Edit" >
            <Button type="text" icon={<EyeOutlined onClick={() => showUpdateModal({ show: true, org: record })} />} />
          </Tooltip>
        </Space>
      )
    }
  ]

  const [data, setData] = useState([])
  const [table, setTable] = useState([])
  const [open, setOpen] = useState(false)
  const [updateModal, showUpdateModal] = useState({ show: false, org: 'empty' })
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()


  const onSearch = (value) => {
    fetchData()
  }

  const onSearchChanged = (value) => {
    setQuery(value.target.value);
  }

  async function removeOrg(org) {
    await deleteOrg(org);
    fetchData();
  }

  const showDeleteConfirm = async (org) => {
    confirm({
      title: 'Are you sure delete this Organization?',
      icon: <ExclamationCircleFilled />,
      content: 'This action is irreversible.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        removeOrg(org)
      },
    })
  }

  const fetchData = async (page) => {
    setLoading(true);
    const response = await getOrgs(page, 5, query);
    if (response.Code === 1) {
      generateList(response.Data, page)
    } else {
      setLoading(false);
      navigate('/login')
    }
  }

  function generateList(data, page) {
    const offset = page ? (page - 1) * 5 : 0
    const list = data.data.map((org, index) => ({
      key: org._id,
      number: (offset + index + 1).toString(),
      name: org.name,
      address: org.address,
      phone: org.phone,
      status: [org.isVerified, org.isActive]
    }))
    setData(data)
    setTable(list)
    setLoading(false)
  };

  useEffect(() => {
    fetchData()
  }, [])


  return (
    <>
      <Helmet>
        <title>Roomie | Organizations</title>
      </Helmet>
      <Title level={3}>Organizations</Title>
      <Divider />
      <Row justify="space-between" style={{ marginBottom: "20px" }}>
        <Col>
          <Search placeholder="Search" onSearch={onSearch} enterButton onChange={onSearchChanged} />
        </Col>
        {env === 'dev' ? <Button type="primary" onClick={() => setOpen(true)}>Add Org</Button> : null}
      </Row>
      <FormModal show={updateModal.show}
        setShow={showUpdateModal}
        refresh={fetchData}
        org={updateModal.org !== 'empty' ? updateModal.org : null}
      />
      <FormModal show={open} setShow={setOpen} refresh={fetchData} />
      <Table
        columns={columns}
        dataSource={table}
        loading={loading}
        pagination={{
          pageSize: 5,
          total: data.totalCount,
          showQuickJumper: true,
          showTotal: (total) => `Total ${total} organizations`,
          onChange: async (page) => {
            await fetchData(page)
          }
        }}
      />
    </>
  )
}

export default OrganizationsView;
