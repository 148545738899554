import { React } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Login, { LoginRoute } from './pages/Login'
import RegisterationView, { RegisterationRoute } from './pages/Registeration'
import Dashboard from './pages/Dashboard';


function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path={LoginRoute} Component={Login} />
          <Route path={RegisterationRoute} Component={RegisterationView} />
          <Route exact path="/*" Component={Dashboard} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
