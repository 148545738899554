import React, { useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { useNavigate } from "react-router-dom";
import {
    Typography, Divider, Form,
    Input, Space, Modal, message
} from "antd";

import { getAdminDetails, updateAdmin, updatePassword } from "../api/organization";

export const SettingsViewRoute = '/settings'

const { Title } = Typography

const layout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 18,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 4,
        span: 16,
    },
};

const SettingsView = () => {
    const [form] = Form.useForm();
    const [passwordForm] = Form.useForm();
    const [admin, setAdmin] = useState({ username: "" });
    const [editable, setEditable] = useState(false);
    const [pwdModalOpen, setPwdModalOpen] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();

    const onFill = (admin) => {
        form.setFieldsValue({
            name: admin.name,
            email: admin.email,
            username: admin.username,
            role: admin.role,
        })
    }

    const fetchData = async () => {
        const data = await getAdminDetails();
        if (data.Code === 1) {
            onFill(data.Data);
            setAdmin(data.Data);
        } else {
            navigate('/')
        }

    }


    useEffect(() => {
        fetchData();
    }, [])

    const onFinish = async (values) => {
        await updateAdmin(admin._id, values)
    }

    const onPasswordSubmit = async (values) => {
        const res = await updatePassword(
            admin._id,
            values.current,
            values.new
        )
        if (res.Code === 1) {
            messageApi.success("Password updated!")
            passwordForm.resetFields();
            setPwdModalOpen(false);
        } else {
            messageApi.error("Wrong Password!")
        }

    }

    const update = () => {
        form.submit();
        setEditable(false);
    }

    const updatePasswordForm = () => {
        passwordForm.submit();
    }

    const updatePasswordCancel = () => {
        passwordForm.resetFields();
        setPwdModalOpen(false);
    }


    return (
        <>
            {contextHolder}
            <Helmet><title>Roomie | Settings</title></Helmet>
            <Title level={3}>Settings</Title>
            <Divider />
            <Form form={form} disabled={!editable} {...layout}
                onFinish={onFinish}
            >
                <Form.Item label="Name" name="name">
                    <Input />
                </Form.Item>

                <Form.Item label="Username" name="username">
                    <Input />
                </Form.Item>
                <Form.Item label="Email" name="email">
                    <Input />
                </Form.Item>
                <Form.Item label="Role" name="role" >
                    <Input disabled />
                </Form.Item>
                <Form.Item {...tailLayout}>
                    <Space direction="horizontal">
                        <a onClick={() => { setEditable(true) }}>Edit</a>
                        {editable ? <a onClick={update}>Update</a> : <></>}
                        {editable ? <a onClick={() => setEditable(false)}>Cancel</a> : <></>}
                        <a onClick={() => setPwdModalOpen(true)}>Change password</a>
                    </Space>
                </Form.Item>
            </Form>
            <Modal title="Update Password"
                open={pwdModalOpen}
                onOk={updatePasswordForm}
                onCancel={updatePasswordCancel}
            >
                <Form form={passwordForm} onFinish={onPasswordSubmit}>
                    <Form.Item
                        name='current'
                        rules={[
                            { required: true, }
                        ]}
                    >
                        <Input.Password placeholder="Current Password" />
                    </Form.Item>
                    <Form.Item
                        name="new"
                        rules={[
                            { required: true, }
                        ]}>
                        <Input.Password placeholder="New Password" />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}


export default SettingsView;

