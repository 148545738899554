import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import logo from '../assets/logo.png'

import {
    Typography, Menu, Layout,
} from 'antd';
import {
    GroupOutlined, SettingOutlined, TeamOutlined,
    UserOutlined, LogoutOutlined,
} from '@ant-design/icons'
import { OrganizationsRoute } from '../pages/Organizations';
import { AdminsViewRoute } from '../pages/Admins';
import { SettingsViewRoute } from '../pages/Settings';
import { logout } from '../api/organization';
import { UsersViewRoute } from '../pages/Users';

const { Sider } = Layout;
const { Title } = Typography;



const Navbar = (props) => {

    const getRoute = (key) => {
        switch (key) {
            case "1":
                return OrganizationsRoute;

            case "2":
                return UsersViewRoute;

            case "3":
                return AdminsViewRoute;

            case "4":
                return SettingsViewRoute;

            default:
                break;
        }
    }


    const navigate = useNavigate();
    const onClick = (key) => {
        const route = getRoute(key.key);
        navigate(route);
    }

    const logOut = async (key) => {
        await logout();
        navigate('/login');
    }



    return <Sider
        trigger={null} collapsible collapsed={props.collapsed} style={{
            background: props.backgroundColor,
            position: 'relative'
        }}>
        <div style={{ margin: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <img src={logo} alt="" style={{ height: 40 }} />
            {!props.collapsed ? <Title level={4} style={{ marginBottom: 25, marginLeft: 10 }}>Roomie</Title> : null}
        </div>
        <Menu
            theme="light"
            mode="inline"
            defaultSelectedKeys={['1']}
            items={[
                {
                    key: '1',
                    icon: <GroupOutlined />,
                    label: 'Organizations'
                },
                {
                    key: '2',
                    icon: <TeamOutlined />,
                    label: 'Users',
                },
                {
                    key: '3',
                    icon: <UserOutlined />,
                    label: 'Admins'
                },
                {
                    key: '4',
                    icon: <SettingOutlined />,
                    label: 'Settings',
                }

            ]}
            onClick={onClick}
        />

        <Menu
            style={{ position: 'absolute', bottom: 16 }}
            theme="light"
            mode="inline"
            items={[
                {
                    key: '5',
                    icon: <LogoutOutlined />,
                    label: 'Logout',
                    danger: true
                },
            ]}
            onClick={logOut}
        >
        </Menu>
    </Sider>
}

Navbar.propTypes = {
    collapsed: PropTypes.bool,
    route: PropTypes.any,
    backgroundColor: PropTypes.string
}

export default Navbar;

