import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import {
    Table, Input, Divider, Row, Col,
    Typography, Button,
} from 'antd';


import { getAdmins } from '../api/organization';
import AdminFormModal from '../components/AdminFormModal'

export const AdminsViewRoute = '/admins';

const { Search } = Input;
const { Title } = Typography;

const AdminsView = () => {
    const [data, setData] = useState([])
    const [table, setTable] = useState([])
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [query, setQuery] = useState("");
    const navigate = useNavigate();

    function generateList(data, page) {
        const offset = page ? (page - 1) * 5 : 0
        const list = data.data.map((admin, index) => ({
            key: admin._id,
            number: (offset + index + 1).toString(),
            name: admin.name,
            username: admin.username,
            email: admin.email,
            role: admin.role,
        }))
        setData(data)
        setTable(list)
        setLoading(false)
    };

    const fetchData = async (page) => {
        setLoading(true)
        const response = await getAdmins(page, 5, query);
        if (response.Code === 1) {
            generateList(response.Data, page)
        } else {
            setLoading(false)
            navigate('/')
        }
    }

    const onSearch = async (value) => {
        fetchData()
    }

    const onSearchChanged = (value) => {
        setQuery(value.target.value);
    }

    useEffect(() => {
        fetchData()
    }, [])

    const columns = [
        {
            title: 'No',
            dataIndex: 'number',
            key: 'sn'
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Username',
            dataIndex: 'username',
            key: 'username'
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email'
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role'
        },


    ]

    return (
        <>
            <Helmet><title>Roomie | Admins</title></Helmet>
            <Title level={3}>Admins</Title>
            <Divider />
            <Row justify="space-between" style={{ marginBottom: "20px" }}>
                <Col>
                    <Search placeholder="Search" onSearch={onSearch} enterButton style={{ marginBottom: "20px" }} onChange={onSearchChanged} />
                </Col>
                <Button type="primary" onClick={() => setOpen(true)}>Add Admin</Button>

            </Row>
            <Table columns={columns} dataSource={table}
                pagination={{
                    pageSize: 5,
                    total: data.totalCount,
                    loading: { loading },
                    showQuickJumper: true,
                    showTotal: (total) => `Total ${total} admins`,
                    onChange: async (page) => {
                        await fetchData(page)
                    }
                }}
            />
            <AdminFormModal show={open} setShow={setOpen} refresh={fetchData} />
        </>
    )
}



export default AdminsView;

