import { React } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import {
    Layout, Button,
    Form, Input, Col, Row,
    Typography, Divider,
    message,
} from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { login } from '../api/organization';
import { OrganizationsRoute } from './Organizations';
import loginImage from '../assets/login.png';
import logo from '../assets/logo.png'

const { Title } = Typography;

export const LoginRoute = '/login';

const Login = () => {
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();

    const onFinish = async (values) => {
        const response = await login(values);
        if (response !== true) {
            messageApi.error(response)
        } else {
            navigate(OrganizationsRoute)
        }

    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };


    return (
        <>
            {contextHolder}
            <Helmet>
                <title>Roomie | Admin Login</title>
            </Helmet>
            <Layout >
                <Row justify='start' align='middle'>
                    <Col align='middle' style={{ width: "50%", height: "100vh", backgroundColor: 'white' }}>
                        <img src={loginImage} alt="login" style={{ width: "100%", padding: '80px', marginTop: "25%", }} />
                    </Col>
                    <Col align="middle" style={{ height: '100vh', width: '50%', padding: "200px" }} >
                        <div style={{ height: '90px', width: '90px', borderRadius: '100px', backgroundColor: 'white' }}>
                            <img src={logo} alt="Roomie logo" style={{ height: "50px", marginTop: '20px' }} />
                        </div><Title>Login</Title>
                        <Divider />
                        <Form
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                        >
                            <Form.Item
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your email!',
                                    },
                                ]}
                            >
                                <Input prefix={<UserOutlined />} placeholder='Email' />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your password!',
                                    },
                                ]}
                            >
                                <Input.Password prefix={<LockOutlined />} placeholder='Password' />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                                    Submit
                                </Button>
                                Or <a href="/register">register now!</a>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </Layout>
        </>
    );
}

export default Login;


