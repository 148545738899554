import { React } from "react";
import PropTypes from 'prop-types';
import {
    Modal, Form, Input,
    Button, Checkbox
} from "antd";
import { createOrg, updateOrg } from "../api/organization";


const layout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 18,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 4,
        span: 16,
    },
};


const FormModal = (props) => {

    const [form] = Form.useForm();

    if (props.org) {
        form.setFieldsValue({
            name: props.org.name,
            address: props.org.address,
            phone: props.org.phone,
            isVerified: props.org.status[0],
            isActive: props.org.status[1] || false,
        })
    }

    const onReset = () => {
        form.resetFields();
    }
    const onFinish = async (values) => {
        if (props.org) {
            await updateOrg(props.org.key, values);
            await props.refresh();
            props.setShow(false);
        } else {
            await createOrg(values);
            await props.refresh();
            props.setShow(false);
        }


    }

    function onCancel() {
        if (props.org) {
            props.setShow({ show: false, org: "empty" })
        } else {
            props.setShow(false);
        }
    }

    return (
        <>
            <Modal
                title={props.org ? "Update Organization" : "Create Organization"}
                centered
                open={props.show}
                onOk={() => { form.submit() }}
                onCancel={() => onCancel()}
                width={1000}
                okButtonProps={{
                    htmlType: "submit"
                }}
                style={{
                    maxWidth: 600,
                }}
            >
                <Form form={form}
                    onFinish={onFinish}
                    {...layout}

                >
                    <Form.Item label="Name" name="name" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="Address" name="address" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="Phone" name="phone" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="Verified" name="isVerified" valuePropName='checked'>
                        <Checkbox />
                    </Form.Item>
                    <Form.Item label="Active" name="isActive" valuePropName='checked'>
                        <Checkbox />
                    </Form.Item>
                    <Form.Item {...tailLayout}>
                        <Button type="link" onClick={onReset}>Reset</Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

FormModal.propTypes = {
    org: PropTypes.object,
    refresh: PropTypes.func,
    setShow: PropTypes.func,
    show: PropTypes.bool,
}

export default FormModal;