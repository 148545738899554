import React, { useState, useEffect } from 'react';
import { useNavigate, Routes, Route } from 'react-router-dom';


import MainLayout from '../components/MainLayout';
import OrganizationsView, { OrganizationsRoute } from './Organizations';
import { getAdminDetails } from '../api/organization'
import AdminsView, { AdminsViewRoute } from './Admins';
import SettingsView, { SettingsViewRoute } from './Settings';
import UsersView, { UsersViewRoute } from './Users';



const Dashboard = () => {
    const [admin, setAdmin] = useState({ username: "" })
    const navigate = useNavigate()


    const fetchData = async (page) => {
        const admin = await getAdminDetails();
        if (admin.Code === 1) {
            setAdmin(admin.Data)
        } else {
            navigate('/login')
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <div>
            <MainLayout username={admin.username}>
                <Routes>
                    <Route exact path={OrganizationsRoute} Component={OrganizationsView} />
                    <Route exact path={UsersViewRoute} Component={UsersView} />
                    <Route exact path={AdminsViewRoute} Component={AdminsView} />
                    <Route exact path={SettingsViewRoute} Component={SettingsView} />
                </Routes>
            </MainLayout>
        </div>
    )
}

export default Dashboard;
